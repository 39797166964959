import React from "react";
import PG_KT_OPTIMIZE_CONTENT from "./index.content";
import "./index.scss";

const Optimize = () => {
  const { Png, Png2x, PngMob, PngMob2x, Webp, Webp2x, WebpMob, WebpMob2x } =
    PG_KT_OPTIMIZE_CONTENT;
  return (
    <section className="PgKT-Optimize">
      <div className="container">
        <h2 className="PgKT-Optimize__title">
          Optimize Your Ads and Watch Your Sales Soar
        </h2>
        <div className="PgKT-Optimize-content">
          <div className="PgKT-Optimize-info">
            <div className="PgKT-Optimize-info__item">
              <div>Don’t Waste Money on Needless Ads</div>
              <div>
                When you generate sales through ads, your organic rank goes up.
                Once you rank high enough for a search term organically there’s
                no point in targeting it with ads. Use the keyword index checker
                to monitor your organic rankings and turn off ads once they’re
                no longer necessary.
              </div>
            </div>
            <div className="PgKT-Optimize-info__item">
              <div>Run Ads for the Right Keywords</div>
              <div>
                You want to make sure that your ads are always growing your
                organic rankings. If you see an online ad that isn’t helping
                your organic rank, fix it by stopping the ad and using that
                money to target a different phrase. This ensures that the money
                you spend brings you the most sales.
              </div>
            </div>
            <div className="PgKT-Optimize-info__item">
              <div>Know When to Start Your Ads</div>
              <div>
                Be alerted the moment your product loses its organic position.
                Start your ads as soon as this happens to continue to rank
                keywords on Amazon and gain an edge over your competitors.
              </div>
            </div>
          </div>
          <div className="PgKT-Optimize__img">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 768px)"
                srcSet={`${Webp} 1x, ${Webp2x} 2x`}
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${Png} 1x, ${Png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${WebpMob} 1x, ${WebpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngMob} 1x, ${PngMob2x} 2x`}
              />
              <img src={Png} alt="" loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Optimize;
