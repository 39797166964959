import React from "react"

import PngOne from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-1.png"
import PngOne_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-1@1_5x.png"
import WebpOne from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-1.webp"
import WebpOne_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-1@1_5x.webp"
import PngTwo from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-2.png"
import PngTwo_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-2@1_5x.png"
import WebpTwo from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-2.webp"
import WebpTwo_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-2@1_5x.webp"
import PngThree from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-3.png"
import PngThree_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-3@1_5x.png"
import WebpThree from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-3.webp"
import WebpThree_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-3@1_5x.webp"
import PngFour from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-4.png"
import PngFour_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-4@1_5x.png"
import WebpFour from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-4.webp"
import WebpFour_1_5x from "../../../assets/images/raster/pages/keyword-tracker/Section1/kwt-sec1-4@1_5x.webp"
import CopyIcon from "../../../assets/images/vector/components/common/link_copy.svg"

const PG_KT_TRACK_CONTENT = [
  {
    pic: PngOne,
    pic1_5: PngOne_1_5x,
    webp: WebpOne,
    webp1_5: WebpOne_1_5x,
    text: () => (
      <>
        <b>Install Keyword Tracker using</b>
        <br />
        <a
          href={
            "https://chrome.google.com/webstore/detail/amazon-keyword-tracker-re/fgkhimnkbaccbhigaeoggfbmggdidjjj"
          }
          target={"_blank"}
        >
          <b>this link </b>
          <img src={CopyIcon} alt="" />
        </a>
      </>
    ),
  },
  {
    pic: PngTwo,
    pic1_5: PngTwo_1_5x,
    webp: WebpTwo,
    webp1_5: WebpTwo_1_5x,
    text: () => (
      <>
        <b>Add a Product:</b> Enter the ASIN for a product from any category in
        the extension
      </>
    ),
  },
  {
    pic: PngThree,
    pic1_5: PngThree_1_5x,
    webp: WebpThree,
    webp1_5: WebpThree_1_5x,
    text: () => (
      <>
        <b>Select Your Keywords:</b> Enter the search words you want to track
        for each product, based on relevance and search volume. Use the keywords
        found by the extension’s indexing algorithm or add your own.
      </>
    ),
  },
  {
    pic: PngFour,
    pic1_5: PngFour_1_5x,
    webp: WebpFour,
    webp1_5: WebpFour_1_5x,
    text: () => (
      <>
        <b>Track Your Rankings:</b> See where each product ranks for your chosen
        search terms
      </>
    ),
  },
]

export default PG_KT_TRACK_CONTENT
