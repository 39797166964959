import React from "react"

import PngXxl from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xxl.png"
import PngXxl2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xxl2x.png"
import PngXl from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xl.png"
import PngXl2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xl2x.png"
import PngLg from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@lg.png"
import PngLg2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@lg2x.png"
import PngMb from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@md.png"
import PngMb2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@md2x.png"
import PngMob from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@mob.png"
import PngMob2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@mob2x.png"
import WebpXxl from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xxl.webp"
import WebpXxl2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xxl2x.webp"
import WebpXl from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xl.webp"
import WebpXl2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@xl2x.webp"
import WebpLg from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@lg.webp"
import WebpLg2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@lg2x.webp"
import WebpMb from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@md.webp"
import WebpMb2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@md2x.webp"
import WebpMob from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@mob.webp"
import WebpMob2x from "../../../assets/images/raster/pages/keyword-tracker/HomeIntro/kwTracker-intro@mob2x.webp"

const PG_KT_HOME_INTRO_CONTENT = {
  PngXxl,
  PngXxl2x,
  PngXl,
  PngXl2x,
  PngLg,
  PngLg2x,
  PngMb,
  PngMb2x,
  PngMob,
  PngMob2x,
  WebpXxl,
  WebpXxl2x,
  WebpXl,
  WebpXl2x,
  WebpLg,
  WebpLg2x,
  WebpMb,
  WebpMb2x,
  WebpMob,
  WebpMob2x,
}

export default PG_KT_HOME_INTRO_CONTENT
