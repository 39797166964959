import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/tickInACircle.svg";
import PG_KT_SOFTWARE_CONTENT from "./index.content";
import "./index.scss";

const Software = () => {
  return (
    <section className="PgKT-Software">
      <div className="container">
        <div className="PgKT-Software-points">
          <h2 className="PgKT-Software-points__title">
            With AMZScout Keyword Tracker Software You Will:
          </h2>
          {PG_KT_SOFTWARE_CONTENT.map((text) => (
            <div key={text} className="PgKT-Software-points__item">
              <img src={PointIcon} alt="" loading="lazy" />
              {text}
            </div>
          ))}
        </div>
        <div className="PgKT-Software__text">
          Most customers buy the products at the top of the search results list,
          whether they’re sold by Amazon, an FBA seller, or someone else. This
          means that keyword optimization is often the difference between barely
          making any money and earning $7,000+ per month in profit.{" "}
        </div>
      </div>
    </section>
  );
};

export default Software;
