import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import PG_KT_LEADER_CONTENT from "./index.content";
import "./index.scss";

const Leader = () => {
  const { Png, Png2x, PngMob, PngMob2x, Webp, Webp2x, WebpMob, WebpMob2x } =
    PG_KT_LEADER_CONTENT;

  return (
    <section className="PgKT-Leader">
      <h2 className="PgKT-Leader__title">Stay Ahead of Your Competitors</h2>
      <div className="PgKT-Leader__subtitle">
        Take advantage of the Reverse ASIN Lookup Tool, which is built into the
        Keyword Tracker app
      </div>
      <div className="PgKT-Leader-content">
        <div className="container">
          <div className="PgKT-Leader-info">
            <div className="PgKT-Leader-info__item">
              <img src={PointIcon} alt="" loading="lazy" />
              <div className="PgKT-Leader-info__item__title">
                <div>See Your Competitors’ Keywords</div>
                <div>
                  Discover which search terms your competitors use and how they
                  rank
                </div>
              </div>
            </div>
            <div className="PgKT-Leader-info__item">
              <img src={PointIcon} alt="" loading="lazy" />
              <div className="PgKT-Leader-info__item__title">
                <div>Find New Keywords</div>
                <div>
                  Copy your competitors’ search terms based on their popularity.
                  Use them for your own listing, backend keywords, and PPC ads.
                </div>
              </div>
            </div>
            <div className="PgKT-Leader-info__item">
              <img src={PointIcon} alt="" loading="lazy" />
              <div className="PgKT-Leader-info__item__title">
                <div>Increase Your Traffic</div>
                <div>
                  Find the most popular phrases and use them in your listing
                  title and description. This improves your SEO and your
                  traffic.
                </div>
              </div>
            </div>
          </div>
          <div className="PgKT-Leader__img">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 768px)"
                srcSet={`${Webp} 1x, ${Webp2x} 2x`}
              />
              <source
                media="(min-width: 768px)"
                srcSet={`${Png} 1x, ${Png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${WebpMob} 1x, ${WebpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${PngMob} 1x, ${PngMob2x} 2x`}
              />
              <img src={Png} alt="" loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Leader;
