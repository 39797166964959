import React from "react"

const PG_KT_SOFTWARE_CONTENT = [
  "Research how many competitors you have for each search term",
  "Track keyword ranks over time and analyze trends with accurate daily reports",
  "Sort product keyword ranking by page and position for multiple keywords",
  "Import and export your data to analyze with other software if you wish",
  "Make sure that a specific phrase appears in the search results with the Amazon index checker",
]

export default PG_KT_SOFTWARE_CONTENT
