import React from "react";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/components/common/mainscreen-kwTracker-intro@xxl.png";
import HomeIntro from "../components/keyword-tracker/HomeIntro";
import Track from "../components/keyword-tracker/Track";
import Software from "../components/keyword-tracker/Software";
import Section3 from "../components/keyword-tracker/Optimize";
import Section4 from "../components/keyword-tracker/Leader";
import SectionWithForm from "../components/keyword-tracker/SectionWithForm";
import ReviewSection from "../components/common/ReviewSection";
import FormSectionBottom from "../components/keyword-tracker/FormSectionBottom";

const PgKT = () => {
  return (
    <Layout>
      <Seo
        title="Amazon Keyword Tracker & Index Checker - Rank Tracking Tool"
        description="Keyword tracker tool for Amazon keyword analysis. Track keyword index and ranking in Amazon search results to improve your listing and optimize PPC. Try for free."
        page="keyword-tracker"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <ReviewSection />
      <Track />
      <Software />
      <SectionWithForm />
      <Section3 />
      <Section4 />
      <FormSectionBottom />
    </Layout>
  );
};

export default PgKT;
