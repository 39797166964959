import React from "react";
import PropTypes from "prop-types";

const HomeIntroScreen = ({ pictureList }) => {
  const {
    PngXxl,
    PngXxl2x,
    PngXl,
    PngXl2x,
    PngLg,
    PngLg2x,
    PngMb,
    PngMb2x,
    PngMob,
    PngMob2x,
    WebpXxl,
    WebpXxl2x,
    WebpXl,
    WebpXl2x,
    WebpLg,
    WebpLg2x,
    WebpMb,
    WebpMb2x,
    WebpMob,
    WebpMob2x,
  } = pictureList;

  return (
    <picture>
      <source
        type="image/webp"
        media="(min-width: 1366px)"
        srcSet={`${WebpXxl} 1x, ${WebpXxl2x} 2x`}
      />
      <source
        media="(min-width: 1366px)"
        srcSet={`${PngXxl} 1x, ${PngXxl2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${WebpXl} 1x, ${WebpXl2x} 2x`}
      />
      <source
        media="(min-width: 1200px) and (max-width: 1365px)"
        srcSet={`${PngXl} 1x, ${PngXl2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${WebpLg} 1x, ${WebpLg2x} 2x`}
      />
      <source
        media="(min-width: 992px) and (max-width: 1199px)"
        srcSet={`${PngLg} 1x, ${PngLg2x} 2x`}
      />
      <source
        type="image/webp"
        media="(min-width: 768px) and (max-width: 992px)"
        srcSet={`${WebpMb} 1x, ${WebpMb2x} 2x`}
      />
      <source
        media="(min-width: 768px) and (max-width: 992px)"
        srcSet={`${PngMb} 1x, ${PngMb2x} 2x`}
      />
      <source
        type="image/webp"
        media="(max-width: 767px)"
        srcSet={`${WebpMob} 1x, ${WebpMob2x} 2x`}
      />
      <source
        media="(max-width: 767px)"
        srcSet={`${PngMob} 1x, ${PngMob2x} 2x`}
      />
      <img src={PngXl} alt="" />
    </picture>
  );
};
HomeIntroScreen.propTypes = {
  pictureList: PropTypes.object,
};
export default HomeIntroScreen;
