import React from "react"

import Png from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic.png"
import Png2x from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@2x.png"
import PngMob from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@mob.png"
import PngMob2x from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@mob2x.png"
import Webp from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic.webp"
import Webp2x from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@2x.webp"
import WebpMob from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@mob.webp"
import WebpMob2x from "../../../assets/images/raster/pages/keyword-tracker/Section3/kwTracker-section3-pic@mob2x.webp"

const PG_KT_OPTIMIZE_CONTENT = {
  Png,
  Png2x,
  PngMob,
  PngMob2x,
  Webp,
  Webp2x,
  WebpMob,
  WebpMob2x,
}

export default PG_KT_OPTIMIZE_CONTENT
