import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { PAGES } from "../../../helpers/constants";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import "./index.scss";

const FormSectionBottom = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section className="PgKT-FormSectionBottom">
      <div className="container">
        <div className="PgKT-FormSectionBottom-info">
          <div className="PgKT-FormSectionBottom__title">
            Get More Sales for Your Money
          </div>
          <div className="PgKT-FormSectionBottom__subtitle">
            Manage your campaigns efficiently and achieve results that would be
            unattainable if you were manually tracking them on the platform. Cut
            less effective ads immediately to maximize your return on ad spend
            and generate more profit.
          </div>
        </div>
      </div>
      <FormBlockContainer
        title="Try AMZScout Keyword Tracker for Free Now"
        customClass={
          isAuth
            ? "PgKT-FormSectionBottom-FormBlockContainer_secondState"
            : "PgKT-FormSectionBottom-FormBlockContainer"
        }
        buttonTextFirstState="Try AMZScout For Free!"
        buttonTextSecondState="TRY AMZSCOUT FOR FREE!"
        page={PAGES.KEYWORD_TRACKER}
      />
    </section>
  );
};

export default FormSectionBottom;
