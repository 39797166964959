import React, { useContext } from "react";
import { MediaAuthContext } from "../../../store/context/media-auth-context";
import { PAGES } from "../../../helpers/constants";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import "./index.scss";

const SectionWithForm = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  return (
    <section className="PgKT-SectionWithForm">
      <h2 className="PgKT-SectionWithForm__title">
        Boost your search rankings so that you can have a best-selling product!
      </h2>
      <FormBlockContainer
        title="Get your free trial today to start ranking higher for profitable search terms"
        customClass={
          isAuth
            ? "PgKT-SectionWithForm-FormBlockContainer_secondState"
            : "PgKT-SectionWithForm-FormBlockContainer"
        }
        buttonTextFirstState="Try AMZScout For Free!"
        buttonTextSecondState="TRY AMZSCOUT FOR FREE!"
        page={PAGES.KEYWORD_TRACKER}
      />
    </section>
  );
};

export default SectionWithForm;
