import React from "react";
import PG_KT_TRACK_CONTENT from "./index.content";
import "./index.scss";

const Track = () => {
  return (
    <section className="PgKT-Track">
      <div className="container">
        <h2 className="PgKT-Track__title">
          Track Amazon Keyword Ranking Changes
        </h2>
        <div className="PgKT-Track__description">
          <p>
            Make sure to get the most sales out of your ad budget and prevent
            competitors from taking your customers.
          </p>
          <p>
            Check where you and your competitors are ranking for search terms
            with <b>AMZScout Amazon Keyword Tracker Extension.</b>
          </p>
        </div>
        <div className="PgKT-Track__prelist-title">Here’s how it works</div>
        <div className="PgKT-Track-list">
          {PG_KT_TRACK_CONTENT.map(
            ({ pic, pic1_5, webp, webp1_5, text }, index) => (
              <div className="PgKT-Track-item" key={pic}>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={`${webp} 1x, ${webp1_5} 2x`}
                  />
                  <img
                    srcSet={`${pic} 1x, ${pic1_5} 2x`}
                    alt=""
                    className="PgKT-Track-item__img"
                    loading="lazy"
                  />
                </picture>
                <div className="PgKT-Track-item__num">0{index + 1}.</div>
                <div className="PgKT-Track-item__text">{text()}</div>
              </div>
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default Track;
