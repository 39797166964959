import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import PG_KT_HOME_INTRO_CONTENT from "./index.content";
import { PAGES } from "../../../helpers/constants";
import FormBlockContainer from "../../../containers/common/forms/FormBlockContainer";
import HomeIntroScreen from "./HomeIntroScreen";
import "./index.scss";

const HomeIntro = () => {

  return (
    <section className="PgKT-HomeIntro">
      <div className="container">
        <div className="PgKT-HomeIntro-row">
          <div className="PgKT-HomeIntro-info">
            <h1 className="PgKT-HomeIntro__title">
              AMZScout <span>Keyword Tracker</span>
            </h1>
            <p className="PgKT-HomeIntro__subtitle">
              Get more sales from your ad budget by precisely monitoring how you
              rank for advertised keywords
            </p>
            <div className="PgKT-HomeIntro-points">
              <div className="PgKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Know when a paid keyword has raised your organic ranking high
                  enough that it’s time to stop spending on it
                </div>
              </div>
              <div className="PgKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Identify search terms that aren’t worth investing advertising
                  money into
                </div>
              </div>
              <div className="PgKT-HomeIntro-points__item">
                <img src={PointIcon} alt="" />
                <div>
                  Track your competitors’ activity and make adjustments if they
                  try to outrank you for crucial key phrases
                </div>
              </div>
            </div>
            <FormBlockContainer
              isIntro
              title=""
              buttonTextFirstState="Try Keyword Tracker For Free! "
              buttonTextSecondState="TRY KEYWORD TRACKER FOR FREE! "
              page={PAGES.KEYWORD_TRACKER}
              customClass="PgKT-HomeIntro-emailCollect"
            />
          </div>
          <div className="PgKT-HomeIntro__img">
            <HomeIntroScreen pictureList={PG_KT_HOME_INTRO_CONTENT} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
